import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getTags } from '../api'

function Aggregator() {
  const { t } = useTranslation()
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  
  const fetchTags = async () => {
    try {
      setLoading(true)
      const { tags } = await getTags()
      setTags(
        tags.find(({ tagName }) => tagName === 'Violation').children
      )
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTags()
  }, [])

  if (error) {
    return (
      <div className="w-full flex items-center justify-center text-white gap-2">
        {error.toString()}
      </div>
    )
  }

  return (
    <>
      {loading ? (
        <div className="w-full flex items-center justify-center text-white gap-2">
          <FontAwesomeIcon
            icon={['fa', 'fa-spinner']}
            className="fa-pulse"
          />
          {t('loading')}..
        </div>
      ) : (
        <>
          {tags.map((attr) => {
            return (
              <div
                className="flex flex-col items-center space-y-4 xl:space-y-8"
                key={attr.id}
              >
                <div className="text-white text-2xl xl:text-4xl font-bold">
                  {attr.tagName}
                </div>
                <div className="text-green-theme text-3xl xl:text-6xl font-bold">
                  {attr.victimsCount}
                </div>
              </div>
            )
          })}
        </>
      )}

    </>
  )
}

const AggregatorWrapper = () => {
  const { t } = useTranslation()

  return (
    <div className="bg-black">
      <div className="container mx-auto py-20 xl:py-24 flex flex-col xl:flex-row justify-between space-y-14 xl:space-y-0">
        <Aggregator />
      </div>
    </div>
  )
}

export default AggregatorWrapper
