import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'gatsby'
import uuid from 'react-uuid'
import { useLocalization } from 'gatsby-theme-i18n'
import DefaultLayout from '../layouts/DefaultLayout'
import Aggregator from '../components/Aggregator'
import { Map, MapToggleButton, AddToMapButton } from '../components/Map'
import Ad from '../components/Ad'
import DataList from '../components/DataList'
import { events } from '../config'
import { getMockedCities } from '../mock/utils'
import Date from '../components/Date'
import { getTags, getPosts } from '../api'
import { findTagColor } from '../util'

const ViolationsMap = () => {
  const { t } = useTranslation()
  const [list, setList] = useState([])
  const [markers, setMarkers] = useState([])
  const [allMarkers, setAllMarkers] = useState([])

  const setActive = useCallback(
    (givenKey) => {
      return (e) => {
        e.preventDefault()

        const updatedList = list.map((item) => {
          return {
            ...item,
            active: item.key === givenKey ? !item.active : item.active,
          }
        })

        setList(updatedList)
      }
    },
    [list]
  )

  const fetchData = async () => {
    const { tags } = await getTags()

    const list = tags
      .find(({ tagName }) => tagName.toLowerCase() === 'violation')
      .children.map((item) => {
        const key = item.tagName
        const color = findTagColor(item.tagName)
        const id = item.id

        return {
          id,
          key,
          color,
          active: true,
        }
      })

    const { posts } = await getPosts({
      filter: {
        tag: {
          in: list.map(({ id }) => id),
        },
      },
    }).then((data) => {
      const posts = data.posts.map((item) => {
        const { location, ...rest } = item
        const latlng = location
        const tags = item.tags
          .map((key) => {
            return list.find((tag) => tag.key === key)
          })
          .map(({ active, ...item }) => {
            return item
          })

        return {
          ...rest,
          tags,
          latlng,
        }
      })

      return {
        ...data,
        posts,
      }
    })

    console.log({
      posts,
    })

    setList(list)
    setAllMarkers(posts)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setMarkers(
      allMarkers.filter(({ tags }) => {
        const activeList = list.filter(({ active }) => active)

        for (const tag of activeList) {
          if (tags.map(({ key }) => key).includes(tag.key)) {
            return true
          }
        }

        return false
      })
    )
  }, [list, setAllMarkers, allMarkers])

  return (
    <>
      <Map className="relative z-0" data={markers} />
      <div className="absolute w-full top-0 z-10">
        <nav className="container mx-auto flex flex-row-reverse items-start justify-center gap-x-5 space-x-reverse">
          {list.map(({ key, color, active }) => (
            <div key={uuid()} className="flex-1 hidden xl:block">
              <MapToggleButton
                className={`bg-${color}`}
                data={{ setActive, active, key }}
              />
            </div>
          ))}

          <div className="xl:flex-1 flex flex-col bg-black text-white text-xl px-7 py-2 xl:py-5 items-center space-y-1 font-bold">
            <span>{t('violations map')}</span>
          </div>
        </nav>
      </div>
      <div className="absolute w-full bottom-0 z-10">
        <div className="w-full flex xl:hidden flex-wrap justify-center mb-10 px-5 mx-auto">
          {list.map(({ key, color, active }) => (
            <div key={uuid()} className="w-1/2 p-1">
              <MapToggleButton
                className={`bg-${color} overflow-hidden`}
                data={{ setActive, active, key }}
              />
            </div>
          ))}
        </div>
        <div className="container mx-auto flex justify-center xl:justify-end">
          <AddToMapButton />
        </div>
      </div>
    </>
  )
}

const ProtestsMap = () => {
  const { t } = useTranslation()
  const [list, setList] = useState([])
  const [markers, setMarkers] = useState([])
  const [allMarkers, setAllMarkers] = useState([])

  const setActive = useCallback(
    (givenKey) => {
      return (e) => {
        e.preventDefault()

        const updatedList = list.map((item) => {
          return {
            ...item,
            active: item.key === givenKey ? !item.active : item.active,
          }
        })

        setList(updatedList)
      }
    },
    [list]
  )

  const fetchData = async () => {
    const { tags } = await getTags()

    const list = tags
      .find(({ tagName }) => tagName.toLowerCase() === 'protests')
      .children.map((item) => {
        const key = item.tagName
        const color = findTagColor(item.tagName)
        const id = item.id

        return {
          id,
          key,
          color,
          active: true,
        }
      })

    console.log({
      list
    })

    const { posts } = await getPosts({
      filter: {
        tag: {
          in: list.map(({ id }) => id),
        },
      },
    }).then((data) => {
      const posts = data.posts.map((item) => {
        const { location, ...rest } = item
        const latlng = location
        const tags = item.tags
          .map((key) => {
            return list.find((tag) => tag.key === key)
          })
          .map(({ active, ...item }) => {
            return item
          })

        return {
          ...rest,
          tags,
          latlng,
        }
      })

      return {
        ...data,
        posts,
      }
    })

    console.log({
      posts,
    })

    setList(list)
    setAllMarkers(posts)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setMarkers(
      allMarkers.filter(({ tags }) => {
        const activeList = list.filter(({ active }) => active)

        for (const tag of activeList) {
          if (tags.map(({ key }) => key).includes(tag.key)) {
            return true
          }
        }

        return false
      })
    )
  }, [list, setAllMarkers, allMarkers])

  return (
    <>
      <Map className="relative z-0" data={markers} />
      <div className="absolute w-full top-0 z-10">
        <nav className="container mx-auto flex flex-row-reverse items-start justify-center gap-x-5 space-x-reverse">
          {list.map(({ key, color, active }) => (
            <div key={uuid()} className="flex-1 hidden xl:block">
              <MapToggleButton
                className={`bg-${color}`}
                data={{ setActive, active, key }}
              />
            </div>
          ))}

          <div className="xl:flex-1 flex flex-col bg-black text-white text-xl px-7 py-2 xl:py-5 items-center space-y-1 font-bold">
            <span>{t('protests map')}</span>
          </div>
        </nav>
      </div>
      <div className="absolute w-full bottom-0 z-10">
        <div className="w-full flex xl:hidden flex-wrap justify-center mb-10 px-5 mx-auto">
          {list.map(({ key, color, active }) => (
            <div key={uuid()} className="w-1/2 p-1">
              <MapToggleButton
                className={`bg-${color} overflow-hidden`}
                data={{ setActive, active, key }}
              />
            </div>
          ))}
        </div>
        <div className="container mx-auto flex justify-center xl:justify-end">
          <AddToMapButton />
        </div>
      </div>
    </>
  )
}

// markup
const IndexPage = (args) => {
  const { data } = args
  const { t, i18n } = useTranslation()
  const { newsPosts, resourcesPosts, reportsPosts } = data
  const { locale } = useLocalization()

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [locale])

  return (
    <DefaultLayout>
      <Aggregator />
      <section className="h-screen xl:h-screen-1/2 relative">
        <ViolationsMap />
      </section>
      <Ad>
        <div className="flex flex-col space-y-10 items-center text-center">
          <div className="text-2xl xl:text-5xl">{t('homeAd1.text')}</div>
          <a
            className="flex flex-col xl:flex-row space-x-1 xl:space-x-2 text-white font-bold text-xl hover:underline"
            href="/#"
          >
            <span>&#62;&nbsp;{t('homeAd1.linkText')}</span>
          </a>
        </div>
      </Ad>
      <section className="h-screen xl:h-screen-1/2 relative">
        <ProtestsMap className="relative z-0" />
      </section>

      {reportsPosts.nodes.length ? (
        <section className="bg-black text-white text-lg xl:text-2xl font-bold py-16">
          <DataList
            data={reportsPosts.nodes}
            titleComponent={() => {
              return (
                <h3 className="flex justify-center xl:justify-between text-green-theme font-bold text-2xl xl:text-4xl space-x-5 xl:space-x-0">
                  <span>{t('reports')}</span>
                </h3>
              )
            }}
            articleTemplate={({ title, link }) => {
              return (
                <h2 className="hover:underline hover:text-green-theme">
                  <a href={link}>{title}</a>
                </h2>
              )
            }}
          />
        </section>
      ) : null}

      {newsPosts.nodes.length ? (
        <section className="py-16">
          <DataList
            data={newsPosts.nodes}
            titleComponent={() => {
              return (
                <h3 className="flex justify-between text-black font-bold text-2xl xl:text-4xl">
                  <span>{t('news')}</span>
                </h3>
              )
            }}
            articleTemplate={({ title, link, date, excerpt }) => {
              return (
                <>
                  <div className="text-gray-500">
                    <Date>{date}</Date>
                  </div>
                  <h2 className="text-xl xl:text-2xl font-bold mb-5 hover:underline">
                    <a href={link}>{title}</a>
                  </h2>
                  <div
                    className="text-lg font-normal"
                    dangerouslySetInnerHTML={{ __html: excerpt }}
                  />
                </>
              )
            }}
          />
        </section>
      ) : null}

      {resourcesPosts.nodes.length ? (
        <section className="bg-black py-16">
          <DataList
            data={resourcesPosts.nodes}
            titleComponent={() => {
              return (
                <h3 className="flex justify-between text-green-theme font-bold text-2xl xl:text-4xl">
                  <span>{t('resources')}</span>
                </h3>
              )
            }}
            articleTemplate={({ title, link }) => {
              return (
                <h2 className="hover:underline hover:text-green-theme text-white text-xl xl:text-2xl font-bold">
                  <a href={link}>{title}</a>
                </h2>
              )
            }}
          />
        </section>
      ) : null}
    </DefaultLayout>
  )
}

export const query = graphql`
  fragment PostFramement on WpPost {
    slug
    title
    date
    excerpt
    language {
      code
    }
  }

  query getPosts($hrefLang: WpLanguageCodeEnum) {
    newsPosts: allWpPost(
      limit: 6
      filter: {
        language: { code: { eq: $hrefLang } }
        categories: { nodes: { elemMatch: { name: { eq: "اخبار" } } } }
      }
    ) {
      nodes {
        ...PostFramement
        translations {
          ...PostFramement
        }
      }
    }

    resourcesPosts: allWpPost(
      limit: 6
      filter: {
        language: { code: { eq: $hrefLang } }
        categories: { nodes: { elemMatch: { name: { eq: "مصادر" } } } }
      }
    ) {
      nodes {
        ...PostFramement
        translations {
          ...PostFramement
        }
      }
    }

    reportsPosts: allWpPost(
      limit: 6
      filter: {
        language: { code: { eq: $hrefLang } }
        categories: { nodes: { elemMatch: { name: { eq: "تقارير" } } } }
      }
    ) {
      nodes {
        ...PostFramement
        translations {
          ...PostFramement
        }
      }
    }
  }
`

export default IndexPage
