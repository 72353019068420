import React from 'react'
import LogoWhiteImage from '../assets/logo-white.svg'

const Ad = ({ children }) => {
  return (
    <div className="bg-black relative py-28 px-5 xl:px-0">
      <div className="absolute left-1/2 transform -translate-x-1/2 -inset-y-1/4 opacity-10 z-0">
        <img className="w-full h-full fit-cover" src={LogoWhiteImage} alt="" />
      </div>
      <div className="container mx-auto text-white relative z-10">
        {children}
      </div>
    </div>
  )
}

export default Ad
