import React from 'react'
import uuid from 'react-uuid'

const DataList = ({
  data,
  className = '',
  articleTemplate: Template,
  titleComponent: TitleComponent,
}) => {
  return (
    <div
      className={`container mx-auto flex flex-col space-y-16 px-5 xl:px-0 ${className}`}
    >
      <TitleComponent />
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-16">
        {data.map((post) => {
          const rtl = post.language.code.toLowerCase() === 'ar'
          post.link = `/${post.language.code.toLowerCase()}/posts/${post.slug}`

          return (
            <article key={post.slug}>
              <Template {...post} />
            </article>
          )
        })}
      </div>
    </div>
  )
}

export default DataList
